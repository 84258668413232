import React from 'react';
import Chapter from '../../components/chapter';
import KeyPoint from '../../components/key-point';
import CatalogLayout from '../../layouts/catalog';

export default () => (
  <CatalogLayout title="文档库">
    <Chapter title="项目生命周期" to="/document/project-lifecycle/" />
    <Chapter title="技术栈" to="/document/tech-stack/" />
  </CatalogLayout>
);
